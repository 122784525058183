import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Sunny Horizon Hotel
			</title>
			<meta name={"description"} content={"Відчуйте повний спектр розкоші та комфорту"} />
			<meta property={"og:title"} content={"Sunny Horizon Hotel"} />
			<meta property={"og:description"} content={"Відчуйте повний спектр розкоші та комфорту"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Наші Послуги
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					У готелі Sunny Horizon ми пишаємося тим, що пропонуємо широкий спектр послуг, покликаних покращити ваше перебування та подарувати вам незабутні враження. Наше прагнення до досконалості гарантує, що кожен аспект вашого візиту обслуговується за найвищими стандартами якості та догляду.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://uploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11:56:00.464Z) bottom/cover no-repeat scroll padding-box"
				lg-min-height="400px"
				lg-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 75%/cover no-repeat scroll padding-box"
				md-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 100%/cover no-repeat scroll padding-box"
				sm-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 50% 100%/cover no-repeat scroll padding-box"
			/>
		</Section>
		<Section padding="40px 0 40px 0" background="#1E1F24" quarkly-title="Description-11">
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
			/>
			<Box border-color="--color-light" width="100%" border-width="0px 0px 1px 0px" border-style="solid">
				<Text margin="0px 0px 2vh 0px" color="--light" font="--lead">
					Наші комплексні зручності
				</Text>
			</Box>
			<Box width="100%" margin="3vw 0px 0px 0px">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline1"
					color="--light"
					letter-spacing="-.05em"
					lg-font="normal 400 70px/1.1em &quot;Inter&quot;, sans-serif"
					sm-font="normal 400 40px/1.1em &quot;Inter&quot;, sans-serif"
				>
					Місце, де розкіш поєднується зі зручністю
				</Text>
			</Box>
			<Box
				margin="156px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			/>
			<Box
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="156px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					Наші зручності розроблені таким чином, щоб задовольнити всі ваші потреби, незалежно від того, приїхали ви сюди для відпочинку, у справах або на особливу подію.
				</Text>
			</Box>
			<Box
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="156px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
			>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
					Від наших добре обладнаних номерів до наших різноманітних зручностей ми гарантуємо, що кожна мить вашого перебування буде винятковою.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Вишукані спа-послуги
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Наш спокійний спа-центр пропонує вам притулок, де ви можете розслабитися та відновити сили. Наші кваліфіковані терапевти надають різноманітні процедури, адаптовані до ваших потреб, забезпечуючи розслаблюючий і відновлюючий досвід.
Масажі: вибирайте з низки варіантів масажу, включно з глибоким масажем, шведським масажем і гарячим камінням, кожен з яких призначений для зняття напруги та заспокоєння м’язів.
Догляд за обличчям: У наших доглядах за обличчям використовуються найкращі продукти для очищення, відлущування та живлення вашої шкіри, залишаючи її сяючою та свіжою.
Догляд за тілом: насолоджуйтеся нашими скрабами та обгортаннями для тіла, призначеними для детоксикації та зволоження вашої шкіри, забезпечуючи омолодження всього тіла.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://images.unsplash.com/photo-1610289982320-3891f7c9fd6d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://images.unsplash.com/photo-1610289982320-3891f7c9fd6d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1610289982320-3891f7c9fd6d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1610289982320-3891f7c9fd6d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1610289982320-3891f7c9fd6d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1610289982320-3891f7c9fd6d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1610289982320-3891f7c9fd6d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1610289982320-3891f7c9fd6d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Вишукані страви
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					У нашому готелі є кілька закладів харчування, кожен з яких пропонує унікальний кулінарний досвід. Від невимушених обідів до вишуканої кухні, наші ресторани задовольнять будь-який смак.
Ресторан для гурманів: насолодіться вишуканою кухнею з меню, створеним нашими досвідченими шеф-кухарями, з найкращими інгредієнтами та інноваційними стравами.
 Сезонне меню: наш ресторан для гурманів пропонує сезонне меню зі свіжих місцевих продуктів.
 Винні поєднання: покращте свою їжу за допомогою наших експертно підібраних винних поєднань, які ідеально доповнюють кожну страву.
Звичайне бістро: для більш спокійного обіду відвідайте наше бістро, де ви можете насолодитися різноманітними комфортними стравами та легкими закусками.
 Харчування протягом усього дня: наше бістро відкрите цілий день, пропонуючи сніданок, обід і вечерю відповідно до вашого розкладу.
 Місця на відкритому повітрі: Скористайтеся нашою зоною відпочинку на відкритому повітрі, яка ідеально підходить для того, щоб насолоджуватися їжею в спокійній атмосфері просто неба.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				order="-1"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11%3A56%3A00.501Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11%3A56%3A00.501Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11%3A56%3A00.501Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11%3A56%3A00.501Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11%3A56%3A00.501Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11%3A56%3A00.501Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11%3A56%3A00.501Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Найсучасніший фітнес-центр
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Залишайтеся активними під час вашого перебування в нашому добре обладнаному фітнес-центрі з найновішим тренажерним обладнанням.
Кардіообладнання. У нашому фітнес-центрі є ряд кардіотренажерів, таких як бігові доріжки, еліптичні тренажери та велотренажери, які допоможуть вам підтримувати свою фізичну форму.
Силові тренування: Ми пропонуємо різноманітне обладнання для силових тренувань, у тому числі вільні ваги та силові тренажери, які допоможуть вам наростити та підтягнути м’язи.
Індивідуальні тренування: Наші сертифіковані персональні тренери готові надати індивідуальні плани тренувань і поради, які допоможуть вам досягти ваших фітнес-цілей.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://images.unsplash.com/photo-1579758629938-03607ccdbaba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://images.unsplash.com/photo-1579758629938-03607ccdbaba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1579758629938-03607ccdbaba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1579758629938-03607ccdbaba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1579758629938-03607ccdbaba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1579758629938-03607ccdbaba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1579758629938-03607ccdbaba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1579758629938-03607ccdbaba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Універсальні місця для подій
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Готель Sunny Horizon стане ідеальним місцем для вашої наступної зустрічі, конференції чи особливого заходу. Наші різноманітні приміщення для проведення заходів можна налаштувати відповідно до ваших потреб.
Кімнати для переговорів: Наші кімнати для переговорів оснащені найсучаснішими технологіями та можуть вмістити групи невеликого та середнього розміру.
 Аудіо-візуальне обладнання: Кожна кімната для переговорів оснащена найсучаснішим аудіо-візуальним обладнанням, щоб ваші презентації були бездоганними та професійними.
 Послуги з організації харчування: Ми пропонуємо послуги з організації харчування, щоб надати вашим відвідувачам смачні страви та закуски протягом усього заходу.
Бальний зал: наш елегантний бальний зал є ідеальним місцем для проведення весіль, урочистостей і масштабних заходів, в якому може прийняти значну кількість гостей.
 Індивідуальний декор: Наша команда з планування подій працюватиме з вами, щоб створити індивідуальний декор, який буде відповідати вашому баченню та темі.
 Варіанти розваг: Ми можемо організувати різноманітні варіанти розваг, від живої музики до послуг ді-джея, щоб покращити вашу подію.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				order="-1"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					І це тільки початок
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Цей огляд наших послуг дає лише уявлення про те, що чекає на вас у готелі Sunny Horizon. Для більш детального розуміння наших пропозицій радимо зв'язатися з нами або відвідати наш готель. Наш привітний персонал завжди готовий допомогти вам і надати додаткову інформацію.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11:56:00.467Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});